import React, { useState, useEffect, useRef } from "react";
import { LoadScript, Autocomplete } from "@react-google-maps/api";
import { useNavigate } from "react-router-dom";
import btnArrow from "../assets/img/section-btn-arrow.webp";
import iconOne from "../assets/img/pincode-icon-1.webp";
import iconTwo from "../assets/img/pincode-icon-2.webp";
import iconThree from "../assets/img/fxry-promise-icon-3.webp";
import checkBox from "../assets/img/check-mark.webp";
import Calendar from "react-calendar";
import payPalIcon from "../assets/img/paypal-icon.png";
import cardIcon from "../assets/img/card-icon.png";
import "react-calendar/dist/Calendar.css";
import axios from "axios";
import { useQuery } from "@tanstack/react-query";
import useCartStore from "../api/cart";
import { loadStripe } from "@stripe/stripe-js";
import AddressForm from "./AddressForm";
import toast from "react-hot-toast";
import { useForm } from "react-hook-form";
import Loader from "./Loader";
const pathURL = process.env.REACT_APP_API_URL;

const fetchCode = async ({ queryKey }) => {
  const id = queryKey[1];
  const { data } = await axios.get(`${pathURL}service/getservicebyitem/${id}`);
  return data;
};

const Checkout = () => {
  const autoCompleteRef = useRef(null);
  const { cartItems, updateCartItem } = useCartStore();
  const {
    register,
    handleSubmit,
    watch,
    setValue,
    formState: { errors },
  } = useForm();
  const userData = JSON.parse(localStorage.getItem("userData"));
  // const { data, error, isLoading, refetch } = useQuery({
  //   queryKey: ["fetchService", cartItems[0]?.itemsData?.item?._id],
  //   queryFn: fetchCode,
  // });
  const [total, setTotal] = useState("00.00");
  const [subscribed, setSubscribed] = useState(false);
  const [yesNo, setyesNo] = useState(false);
  const [payment, setPayment] = useState(false);
  const [selected, setSelected] = useState(1);
  const [userInfo, setuserInfo] = useState({
    personalInfo: {},
    address: {},
    deliveryInfo: { collectionDate: null },
    paymentInfo: {},
  });

  const handleSubscriptionClick = () => {
    setSubscribed(!subscribed);
  };
  const handleYesNoClick = () => {
    setyesNo(!yesNo);
  };
  const handlePaymentClick = () => {
    setPayment(!payment);
  };
  const personalDetailRef = useRef(null);
  const addressDetailRef = useRef(null);
  const deliveryDetailRef = useRef(null);
  const paymentDetailRef = useRef(null);

  const scrollToPersonalDetail = () => {
    personalDetailRef.current.scrollIntoView({ behavior: "smooth" });
    setSelected(1);
  };
  const scrollToAddressDetail = () => {
    addressDetailRef.current.scrollIntoView({ behavior: "smooth" });
    setSelected(2);
  };
  const scrollToDeliveryDetail = () => {
    deliveryDetailRef.current.scrollIntoView({ behavior: "smooth" });
    setSelected(3);
  };
  const scrollToPaymenyDetail = () => {
    paymentDetailRef.current.scrollIntoView({ behavior: "smooth" });
    setSelected(4);
  };

  const onChange = (date) => {
    setuserInfo({
      ...userInfo,
      deliveryInfo: { ...userInfo["deliveryInfo"], collectionDate: date },
    });
  };

  const navigate = useNavigate();
  const [Numbererror, setNumberError] = useState("");

  const validatePhoneNumber = (phoneNumber) => {
    if (phoneNumber.startsWith("+1")) {
      phoneNumber = phoneNumber.substring(2).trim();
    }

    const phoneNumberPattern = /^\(?\d{3}\)?[-.\s]?\d{3}[-.\s]?\d{4}$/;
    return phoneNumberPattern.test(phoneNumber);
  };

  const phoneNumber = watch("number");

  const formatPhoneNumber = (value) => {
    const phoneNumber = value.replace(/[^\d]/g, "");
    const regex = /^\(?(\d{3})\)?[-. ]?(\d{3})[-. ]?(\d{4})$/;
    let formattedNumber;

    if (regex.test(phoneNumber)) {
      formattedNumber = phoneNumber.replace(regex, "($1) $2-$3");
      return { formattedNumber, isValid: true };
    } else {
      return { formattedNumber: phoneNumber, isValid: false };
    }
  };

  const [payLoading, setPayLoading] = useState(false);

  useEffect(() => {
    const { formattedNumber, isValid } = formatPhoneNumber(phoneNumber || "");
    setValue("number", formattedNumber, {
      shouldValidate: true,
      shouldDirty: true,
    });
  }, [phoneNumber, setValue]);

  const inputChangehandler = (value) => {
    const { type, e } = value;
    if (e.target.name === "number") {
      if (/^[0-9-\s()]*$/.test(e.target.value)) {
        let phoneNumber = e.target.value;
        setuserInfo({
          ...userInfo,
          [type]: { ...userInfo[type], [e.target.name]: phoneNumber },
        });
        if (phoneNumber === "" || validatePhoneNumber(phoneNumber)) {
          setNumberError("");
        } else {
          setNumberError(
            "Invalid US phone number. Please enter a valid phone number."
          );
        }
      }
    } else {
      setuserInfo({
        ...userInfo,
        [type]: { ...userInfo[type], [e.target.name]: e.target.value },
      });
    }
  };
  const [collectionDateError, setCollectionDateError] = useState(null);
  const handleApply = async (data) => {
    console.log("runn");
    if (userInfo.deliveryInfo.collectionDate == null) {
      setCollectionDateError("Please select the collection date!");
      return;
    }
    const response1 = await axios.get(
      `${pathURL}zipcodes/getone/${data?.code}`
    );

    if (response1.data && response1.data != null) {
      const user = {
        email: data?.email,
        fullName: data?.fullName,
        number: data.number,
        subscribed: data?.subscribed != undefined ? subscribed : false,
        address: {
          addressText: data.addressText,
          line1: data.addressText,
          line2: data.line2,
          city: data.city,
          code: data.code,
          state: data?.state,
        },
        deliveryInfo: userInfo.deliveryInfo,
      };
      updateCartItem(cartItems[0].id, {
        user: {
          email: data?.email,
          fullName: data?.fullName,
          number: data.number,
          subscribed: data?.subscribed != undefined ? subscribed : false,
          address: {
            addressText: data.addressText,
            line1: data.addressText,
            line2: data.line2,
            city: data.city,
            code: data.code,
            state: data?.state,
          },
          deliveryInfo: { ...userInfo.deliveryInfo, leavewithneighbour: yesNo },
        },
      });
      const postData = cartItems.map((item) => {
        return {
          ...item,
          user: {
            personalInfo: {
              email: data?.email,
              fullName: data?.fullName,
              number: data.number,
              subscribed: data?.subscribed != undefined ? subscribed : false,
              address: {
                addressText: data.addressText,
                line1: data.addressText,
                line2: data.line2,
                city: data.city,
                code: data.code,
                state: data?.state,
              },
            },
            deliveryInfo: userInfo.deliveryInfo,
            address: {
              addressText: data.addressText,
              line1: data.addressText,
              line2: data.line2,
              city: data.city,
              code: data.code,
              state: data?.state,
            },
            paymentInfo: {},
          },
          discountCode: code.toUpperCase(),
          discountValue: discount,
          deliveryType: null
        };
      });
      setPayLoading(true);
      const stripe = await loadStripe(process.env.REACT_APP_STRIPE_API_KEY);
      const response = await axios.post(`${pathURL}order/create`, postData[0]);

      if (response.status == 200) {
        const sessionId = response.data.sessionId;
        const result = stripe.redirectToCheckout({
          sessionId: sessionId,
        });
      }
    } else {
      setCollectionDateError(
        "Sorry, this area is not yet supported for delivery, yet."
      );
    }
  };

  const [subtotalAmount, setsubtotalAmount] = useState(0);
  const [subtotalAmount1, setsubtotalAmount1] = useState(0);
  const [withoutTaxTotal, setwithoutTaxTotal] = useState(0);
  const [subtotalTaxAmount, setsubtotalTaxAmount] = useState(0);
  useEffect(() => {
    if (cartItems && cartItems?.length == 0) {
      localStorage.removeItem("product-store");
      navigate("/");
    }
    if (cartItems[0]?.itemsData?.length == 0 && cartItems[0]?.services?.length == 0) {
      localStorage.removeItem("product-store");
      navigate("/");
    }
    const totalAmount = cartItems[0]?.services.reduce((acc, item) => {
      const pricingPlan = cartItems[0]?.itemsData?.find((itm) => { return item?.item_details?.id === itm?.id; });
      if (pricingPlan) {
        const updatedPrice = pricingPlan?.pricing_plan === "Standard" ? item.standard_price : item.premium_price;
        const mainprice = acc + parseFloat(updatedPrice);
        const percentage = 10.35;
        const totalTax = (percentage / 100) * mainprice;
        setsubtotalTaxAmount(totalTax);
        setsubtotalAmount1(mainprice);
        setsubtotalAmount(mainprice + totalTax + 5);
        setwithoutTaxTotal(mainprice + totalTax + 5);
        return mainprice;
      }
      return acc;
    }, 0);
  }, []);


  const [handleDiscErr, setHandleDiscErr] = useState(null);
  const [showdiscount, setShowDiscount] = useState(false);
  const [discount, setDiscount] = useState(null);
  const [code, setcode] = useState("");
  const handleDiscountApply = async () => {
    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}discounts/getone/${code}`
    );
    if (response.status == 200 && response.data) {
      setShowDiscount(true);
      if (response?.data?.type == "fixed") {
        setDiscount(parseFloat(response?.data?.amount));
        setsubtotalAmount(subtotalAmount - parseFloat(response?.data?.amount));
        updateCartItem(cartItems[0].id, {
          totalDiscount: parseFloat(response?.data?.amount),
        });
      }
      if (response?.data?.type == "percentage") {
        let percentage = parseFloat(response.data.amount);
        let totalDiscount = (percentage / 100) * subtotalAmount1;
        setDiscount(totalDiscount);
        setsubtotalAmount(subtotalAmount - totalDiscount);
        updateCartItem(cartItems[0].id, { totalDiscount: totalDiscount });
      }
    }

    if (!response.data) {
      toast.error("Invalid code !");
      setcode("");
    }

    if (response?.data?.length == 0) {
      setHandleDiscErr("Discount code not applicable!");
    }
  };

  const removeDiscount = () => {
    setsubtotalAmount(withoutTaxTotal);
    setShowDiscount(false);
    setDiscount(null);
    setcode("");
    updateCartItem(cartItems[0].id, {
      totalDiscount: 0,
    });
  };

  const handleCodeSet = (e) => {
    if (e.target.value != "") {
      setcode(e.target.value.toUpperCase());
    } else {
      removeDiscount();
    }
  };

  const handlePlaceSelect = () => {
    const addressObject = autoCompleteRef.current.getPlace();
    if (addressObject) {
      const addressComponents = addressObject.address_components;
      let streetNumber = "";
      let route = "";

      addressComponents.forEach((component) => {
        const types = component.types;
        if (types.includes("subpremise")) {
          setValue("line2", component.long_name);
        }
        if (types.includes("street_number")) {
          streetNumber = component.long_name;
        }
        if (types.includes("route")) {
          route = component.long_name;
        }
        if (types.includes("locality")) {
          setValue("city", component.long_name);
        }
        if (types.includes("administrative_area_level_1")) {
          setValue("state", component.long_name);
        }
        if (types.includes("postal_code")) {
          setValue("code", component.long_name);
        }
      });

      const addressText = `${streetNumber} ${route}`.trim();
      setValue("addressText", addressText);
      setValue("line1", addressText);
    }
  };

  useEffect(() => {
    if (autoCompleteRef.current) {
      autoCompleteRef.current.setFields([
        "address_components",
        "formatted_address",
      ]);
    }
  }, []);

  const [date, setDate] = useState(new Date());

  const specificDisabledDates = [
    new Date(2024, 8, 2)
  ];

  const filterDates = (date) => {
    const isMondayOrWednesday = date.getDay() === 1 || date.getDay() === 3 || date.getDay() === 5;
    return isMondayOrWednesday;
  };

  // Function to limit the calendar to the next 30 days
  const isWithin30Days = (date) => {
    const today = new Date();
    const thirtyDaysLater = new Date();
    thirtyDaysLater.setDate(today.getDate() + 30);
    return date <= thirtyDaysLater;
  };

  const tileDisabled = ({ date }) => {
    const today = new Date();
    const isAfter4PM = today.getHours() >= 16;

    // Calculate the next available date
    const nextAvailableDate = new Date(today);

    if (nextAvailableDate.getDay() === 0 || nextAvailableDate.getDay() === 2 || nextAvailableDate.getDay() === 4 || nextAvailableDate.getDay() === 6) {
      nextAvailableDate.setDate(today.getDate() + 1);
    } else {
      nextAvailableDate.setDate(today.getDate() + 2);
    }

    // Check if the date is in the specificDisabledDates array
    const isSpecificDisabledDate = specificDisabledDates.some(
      (disabledDate) =>
        date.getFullYear() === disabledDate.getFullYear() &&
        date.getMonth() === disabledDate.getMonth() &&
        date.getDate() === disabledDate.getDate()
    );

    // Logic to disable next available day if today is after 4 PM
    const disableNextAvailableDay = isAfter4PM &&
      date.getDate() === nextAvailableDate.getDate() &&
      date.getMonth() === nextAvailableDate.getMonth() &&
      date.getFullYear() === nextAvailableDate.getFullYear();

    return (
      isSpecificDisabledDate ||
      (date.getDate() === today.getDate() &&
        date.getMonth() === today.getMonth() &&
        date.getFullYear() === today.getFullYear() &&
        isAfter4PM) ||
      disableNextAvailableDay ||
      !filterDates(date) ||
      !isWithin30Days(date) || date.getDate() === today.getDate()
    );
  };

  return (
    <section className="checkout-section item-detail-section items-section same-bg-section relative">
      {payLoading ? (
        <Loader />
      ) : (
        <div className="container padding-x-all">
          <div className="item-detail-box sec-com-padding flex-box justify-between align-center">
            <div className="checkout-left-box">
              <div className="promo-code-box">
                <form className="promo-code">
                  <input
                    type="text"
                    placeholder="Promo code"
                    value={code}
                    onChange={handleCodeSet}
                  />
                  <button
                    className="promo-code-btn"
                    type="button"
                    disabled={code == "" ? true : false}
                    onClick={handleDiscountApply}
                  >
                    Apply
                  </button>
                </form>
                {handleDiscErr == null && (
                  <p
                    className="para"
                    style={{ marginTop: "0.4rem", color: "red" }}
                  >
                    {handleDiscErr}
                  </p>
                )}
                <div className="checkout-sub-total">
                  {cartItems[0]?.services?.map((carts, index) => {
                    let pricingPlan = cartItems[0]?.itemsData?.find((itm) => carts?.item_details?.id == itm?.id);
                    return (
                      <>
                        <div className="flex-box justify-between">
                          <h6>{carts?.name}</h6>
                          <h6>
                            ${pricingPlan?.pricing_plan === "Standard" ? carts.standard_price : carts.premium_price}
                          </h6>
                        </div>
                      </>
                    );
                  })}
                  <div className="flex-box justify-between">
                    <h6>Pickup & Drop-off fee</h6>
                    <h6>$5.00</h6>
                  </div>
                  <div className="flex-box justify-between">
                    <h6>Total Tax</h6>
                    <h6>${subtotalTaxAmount.toFixed(2)}</h6>
                  </div>
                  {showdiscount && (
                    <div className="flex-box justify-between">
                      <h6>Discount</h6>
                      <h6>${discount.toFixed(2)}</h6>
                      <span
                        onClick={removeDiscount}
                        style={{ cursor: "pointer" }}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 50 50"
                          width="20px"
                          height="20px"
                        >
                          <path d="M 25 2 C 12.309534 2 2 12.309534 2 25 C 2 37.690466 12.309534 48 25 48 C 37.690466 48 48 37.690466 48 25 C 48 12.309534 37.690466 2 25 2 z M 25 4 C 36.609534 4 46 13.390466 46 25 C 46 36.609534 36.609534 46 25 46 C 13.390466 46 4 36.609534 4 25 C 4 13.390466 13.390466 4 25 4 z M 32.990234 15.986328 A 1.0001 1.0001 0 0 0 32.292969 16.292969 L 25 23.585938 L 17.707031 16.292969 A 1.0001 1.0001 0 0 0 16.990234 15.990234 A 1.0001 1.0001 0 0 0 16.292969 17.707031 L 23.585938 25 L 16.292969 32.292969 A 1.0001 1.0001 0 1 0 17.707031 33.707031 L 25 26.414062 L 32.292969 33.707031 A 1.0001 1.0001 0 1 0 33.707031 32.292969 L 26.414062 25 L 33.707031 17.707031 A 1.0001 1.0001 0 0 0 32.990234 15.986328 z" />
                        </svg>
                      </span>
                    </div>
                  )}
                  <div className="flex-box justify-between">
                    <h6>Subtotal</h6>
                    <h6>${subtotalAmount.toFixed(2)}</h6>
                  </div>
                </div>
                <div className="checkout-total flex-box justify-between">
                  <h5>Total</h5>
                  <h5>
                    $
                    {(subtotalAmount).toFixed(2)}
                  </h5>
                </div>
              </div>
              <div className="frxy-promise">
                <h4 className="fxry-promise-text">The FXRY Promise..!</h4>
                <ul className="fxry-usp-listing pincode-usp-listing flex-box">
                  <li>
                    <div className="img-box">
                      <img src={iconOne} alt="" />
                    </div>
                    <div className="text-box">
                      <p>
                        <strong>Hassle Free </strong> <br />
                        Transactions
                      </p>
                    </div>
                  </li>
                  <li>
                    <div className="img-box">
                      <img src={iconTwo} alt="" />
                    </div>
                    <div className="text-box">
                      <p>
                        100% Bespoke <br />
                        <strong> Solutions</strong>
                      </p>
                    </div>
                  </li>
                  <li>
                    <div className="img-box">
                      <img src={iconThree} alt="" />
                    </div>
                    <div className="text-box">
                      <p>
                        <strong>Doorstep </strong> <br />
                        Delivery
                      </p>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
            <div className="sub-total-box">
              <div className="user-detail-head-box flex-box justify-between">
                <div
                  ref={personalDetailRef}
                  className={`${selected === 1 ? "selected" : ""}`}
                  style={{
                    backgroundColor: selected === 1 ? "#E2E21A" : "#fff",
                  }}
                  onClick={() => scrollToPersonalDetail()}
                >
                  <h6>01</h6>
                  <h5>Personal Detail</h5>
                </div>
                <div
                  ref={addressDetailRef}
                  className={`${selected === 2 ? "selected" : ""}`}
                  style={{
                    backgroundColor: selected === 2 ? "#E2E21A" : "#fff",
                  }}
                  onClick={() => scrollToAddressDetail()}
                >
                  <h6>02</h6>
                  <h5>Your Address</h5>
                </div>
                <div
                  ref={deliveryDetailRef}
                  className={`${selected === 3 ? "selected" : ""}`}
                  style={{
                    backgroundColor: selected === 3 ? "#E2E21A" : "#fff",
                  }}
                  onClick={() => scrollToDeliveryDetail()}
                >
                  <h6>03</h6>
                  <h5>Delivery Details</h5>
                </div>
              </div>
              <div className="user-detail-main-box scroll-wrapper">
                <form onSubmit={handleSubmit(handleApply)}>
                  <div
                    className="personal-detail-box form-section-border"
                    ref={personalDetailRef}
                  >
                    <h4>Personal Details</h4>
                    <div className="field-box">
                      <label className="label-on-line">Full Name*</label>
                      <input
                        type="text"
                        // name={"fullName"}
                        {...register("fullName", {
                          required: true,
                          maxLength: 20,
                        })}
                        onChange={(e) =>
                          inputChangehandler({ type: "personalInfo", e })
                        }
                      />
                      {errors.fullName?.type === "required" && (
                        <p role="alert">Name is required</p>
                      )}
                    </div>
                    <div className="field-box">
                      <label className="label-on-line">Email*</label>
                      <input
                        type="email"
                        {...register("email", {
                          required: true,
                          pattern: {
                            value:
                              /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
                            message: "Please enter a valid email address",
                          },
                          maxLength: {
                            value: 100,
                            message: "Email should not exceed 100 characters",
                          },
                        })}
                        onChange={(e) =>
                          inputChangehandler({ type: "personalInfo", e })
                        }
                      />
                      {errors.email && <p>{errors.email.message}</p>}
                    </div>
                    <p
                      className={`para click-to-change ${subscribed ? "subscribed" : ""
                        }`}
                      onClick={handleSubscriptionClick}
                    >
                      <span
                      // style={{
                      //   backgroundColor: subscribed ? "grey" : "yellow",
                      // }}
                      >
                        {/* <img src={checkBox} alt="" /> */}
                        <input type="checkbox" {...register("subscribed")} defaultChecked />
                      </span>
                      Yes, I want to subscribe to FXRY’s newsletter and receive
                      tailored updates straight to my inbox!
                    </p>
                    <div className="field-box">
                      <label className="label-on-line"> Mobile Number* </label>
                      <input
                        type="tel"
                        {...register("number", {
                          required: true,
                          pattern: {
                            value: /^(\(?\d{3}\)?[-. ]?\d{3}[-. ]?\d{4})$/,
                            message: "Please enter a valid US phone number",
                          },
                          // maxLength: {
                          //   value: 14, // considering the format with separators (e.g., (123) 456-7890)
                          //   message: "Phone number should not exceed 14 characters"
                          // }
                        })}
                        maxLength={10}
                      // placeholder="e.g. +12067620760"
                      />
                    </div>
                    {errors.number && (
                      <p className="error-message">{errors.number.message}</p>
                    )}
                    <p className="smallest-para">
                      By continuing, you agree to FXRY’s{" "}
                      <a
                        className="text-black"
                        target="blank"
                        href="https://www.thefxry.com/terms-and-conditions"
                      >
                        Terms and Conditions.
                      </a>
                    </p>
                  </div>

                  <div
                    ref={addressDetailRef}
                    className="your-address-box form-section-border"
                  >
                    {/* <LoadScript
                      googleMapsApiKey="AIzaSyDpLsQIPdnYXxvfK3_pUuCHkNz57LRVado"
                      libraries={["places"]}
                    > */}
                      <h4>Your Address</h4>
                      {/* <Autocomplete
                        onLoad={(autocomplete) =>
                          (autoCompleteRef.current = autocomplete)
                        }
                        onPlaceChanged={handlePlaceSelect}
                      >
                        <>

                        </>
                      </Autocomplete> */}
                      <div className="field-box">
                        <label className="label-on-line">Line 1*</label>
                        <input
                          type="text"
                          onChange={(e) => {
                            setValue("line1", e.target.value);
                            setValue("addressText", e.target.value);
                          }}
                          {...register("addressText", {
                            required: true,
                          })}
                        />
                      </div>
                      {errors.addressText?.type === "required" && (
                        <p role="alert">This field is required</p>
                      )}

                      {/* <p className="underlined-text">Enter address manually</p> */}
                      {/* <div
                        className="field-box"
                        style={{ visibility: "hidden" }}
                      >
                        <label className="label-on-line">Line 01*</label>
                        <input
                          type="hidden"
                          {...register("line1", { required: true })}
                        />
                        {errors.line1?.type === "required" && (
                          <p role="alert">Line 01 is required</p>
                        )}
                      </div> */}

                      <div className="field-box">
                        <label className="label-on-line">Line 2</label>
                        <input type="text" {...register("line2")} />
                      </div>

                      <div className="field-box">
                        <label className="label-on-line">City*</label>
                        <input
                          type="text"
                          {...register("city", { required: true })}
                        />
                        {errors.city?.type === "required" && (
                          <p role="alert">City is required</p>
                        )}
                      </div>

                      <div className="field-box">
                        <label className="label-on-line">State*</label>
                        <input
                          type="text"
                          {...register("state", { required: true })}
                        />
                        {errors.state?.type === "required" && (
                          <p role="alert">State is required</p>
                        )}
                      </div>

                      <div className="field-box">
                        <label className="label-on-line">Zip Code*</label>
                        <input
                          type="text"
                          maxLength={5}
                          {...register("code", { required: true })}
                        />
                        {errors.code?.type === "required" && (
                          <p role="alert">Zip code is required</p>
                        )}
                      </div>
                    {/* </LoadScript> */}
                  </div>
                  <div className="collections-box form-section-border">
                    <h4>Pickup</h4>
                    <p className="smallest-para">
                      Receive your items back in 12-14 days from pickup.
                    </p>
                    <p className="smallest-para">
                      Pickup is between 10am - 6pm.
                    </p>
                    <p className="smallest-para">
                      Estimated time of arrival will be shared the day before.
                    </p>

                    <Calendar
                      minDate={new Date()}
                      onChange={onChange}
                      value={userInfo.deliveryInfo.collectionDate}
                      // tileDisabled={({ date }) => !filterDates(date) || !isWithin30Days(date) || specificDisabledDates.some(disabledDate =>  date.getFullYear() === disabledDate.getFullYear() && date.getMonth() === disabledDate.getMonth() && date.getDate() === disabledDate.getDate())}
                      tileDisabled={tileDisabled}
                    />
                    {errors.collectionDate && (
                      <span>{errors.collectionDate.message}</span>
                    )}
                    {userInfo.deliveryInfo.collectionDate != null && (
                      <p
                        className="para"
                        style={{
                          fontWeight: "bold",
                          fontSize: "0.8rem",
                          color: "black",
                          marginTop: "1rem",
                        }}
                      >
                        Pickup date selected for{" "}
                        {new Date(
                          userInfo?.deliveryInfo?.collectionDate
                        ).toLocaleString("en-US", {
                          day: "numeric",
                          month: "long",
                          weekday: "long",
                          year: "numeric",
                        })}
                      </p>
                    )}
                    {collectionDateError == null && (
                      <p
                        className="para"
                        style={{
                          fontWeight: "bold",
                          fontSize: "0.8rem",
                          color: "black",
                        }}
                      >
                        {collectionDateError}
                      </p>
                    )}
                  </div>
                  <div
                    className="form-delivery-box  form-section-border"
                    ref={deliveryDetailRef}
                  >
                    <h4>Delivery</h4>
                    <p className="para">
                      Can we leave the order with a neighbor?
                    </p>
                    <div className="yes-no-box flex-box">
                      <div
                        className={`${yesNo ? "yesNo" : ""}`}
                        style={{
                          backgroundColor: yesNo ? "#E2E21A" : "#D6D1C4",
                        }}
                        onClick={handleYesNoClick}
                      >
                        Yes
                      </div>
                      <div
                        className={`${yesNo ? "yesNo" : ""}`}
                        style={{
                          backgroundColor: !yesNo ? "#E2E21A" : "#D6D1C4",
                        }}
                        onClick={handleYesNoClick}
                      >
                        No
                      </div>
                    </div>
                    <h6 className="form-question">
                      Is there a safe place to leave the delivery?
                    </h6>
                    <input
                      type="text"
                      placeholder="No"
                      name={"basic_instruction"}
                      onChange={(e) =>
                        inputChangehandler({ type: "deliveryInfo", e })
                      }
                      maxLength={100}
                    />
                    <h6 className="form-question">
                      Extra delivery instructions
                    </h6>
                    <input
                      className="mb-0"
                      type="text"
                      placeholder="No"
                      maxLength={100}
                      name={"extra_instruction"}
                      onChange={(e) =>
                        inputChangehandler({ type: "deliveryInfo", e })
                      }
                    />
                  </div>

                  <div className="payment-info-box" ref={paymentDetailRef}>
                    <div className="paypal-detail-box"></div>
                    <button className="section-main-btn" type="submit">
                      Pay Now
                      <span>
                        <img src={btnArrow} alt="" />
                      </span>
                    </button>
                  </div>
                  {collectionDateError && (
                    <p
                      className="para"
                      style={{
                        fontWeight: "bold",
                        fontSize: "0.8rem",
                        color: "black",
                      }}
                    >
                      {collectionDateError}
                    </p>
                  )}
                </form>
              </div>
            </div>
          </div>
        </div>
      )}
    </section>
  );
};

export default Checkout;
